import React from 'react'
import { useState } from 'react'

const Inquiry = () => {

  const [complete, setComplete] = useState(false)
  const [service, setService] = useState('Select Your Service...')
  const [email, setEmail] = useState('')
  const [fName, setFName] = useState('')
  const [lName, setLName] = useState('')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')

  // Form Submission Function
  const submit = () => {
    // Simple POST request with a JSON body using fetch()
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        to: 'info@jbridgewater.com',
        subject: 'New Inquiry From: ' + name,
        text: `Contact Information:\n
        Name: ${name}\n
        Email: ${email}\n
        Phone: ${phone}\n\n
        Service: ${service}`
      })
    }
    fetch('https://www.api.jbridgewater.com/api/sendMessage', requestOptions)
      .catch(err => err)
  }
  return (
    <div>
      {(
        <div>{
          (complete) ? 
          (
            <div className='bd-blur-7px bg-white bx-shadow-gray pad-5'>
              <div>
                <h3 className='center hero-color'>CONGRATULATIONS!</h3>
                <p>You've taken your first step towards your new <b className='bold'>{service}</b>! </p>
                <p>A member of our team will contact you shortly for your free consultation.</p>
                <h4>Your Information</h4>
                <table className='text-align-left'>
                  <tr>
                    <td>Your Name:</td><td>{name}</td>
                  </tr>
                  <tr>
                    <td>Email Address:</td><td>{email}</td>
                  </tr>
                  <tr>
                    <td>Phone Number:</td><td>{phone}</td>
                  </tr> 
                  <tr>
                    <td>Service:</td><td>{service}</td>
                  </tr>
                </table>   
              </div>
            </div>
          ) : 
          (
            <div className='bg-white bx-shadow-gray f-22px inline-block pad-3 rel w-auto'>
              <h3 className='center text-shad-lightgray'>Start Your Project Now!</h3><br></br>
              <form>
                <input autoComplete='true' className='required' id='name' onChange={(e: any) => { setName(e.target.value) }} placeholder='Full Name...' type='text' tabIndex={1}></input><br></br>
                <input autoComplete='true' className='required' id='email' onChange={(e: any) => { setEmail(e.target.value) }} placeholder='Email Address...' type='email'></input><br></br>
                <input autoComplete='true'  className='' id='phone' onChange={(e: any) => { setPhone(e.target.value) }} placeholder='Phone Number...' type='phone'></input><br></br><br></br>
                <select className='required' onChange={(e) => {setService(e.target.value)}}>
                  <option selected value={'Select Your Service...'}>Select Your Service...</option>
                  <option value={'Patio Screens'}>Patio Screens</option>
                  <option value={'Re-Screening'}>Re-Screening</option>
                  <option value={'Pool Enclosures'}>Pool Enclosures</option>
                  <option value={'Window Screens'}>Window Screens</option>
                </select>
                <div className='flex center marY-5 pad-3 pointer'>
                  {(name !== '') && (email !== '') && (service !== 'Select Your Service...') && (
                    <div className='hero-btn submitBtn bold box-shad-gray pad-3' onClick={() => {submit(); setComplete(true)}} role='button'>SUBMIT</div>
                  )}
                </div>
              </form>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Inquiry