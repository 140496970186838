import React from 'react'
import ChevronUp from '../icons/ChevronUp'

const ToTop = () => {

  //event listener to check for scroll height
  window.addEventListener('scroll', () => {

    //logic to show or hide the toTop button
    if (window.scrollY > 0) {
      showTopBtn()
    } else {
      hideTopBtn()
    }
  })

  //function to scroll to top
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  //function to show the top button
  const showTopBtn = () => {
    const TopBtn = document.querySelector('#toTop')
    TopBtn != null && TopBtn.classList.add('visible');
  }

  //function to hide the top button
  const hideTopBtn = () => {
    const TopBtn = document.querySelector('#toTop')
    TopBtn != null && TopBtn.classList.remove('visible')
  }

  //html to be rendered
  return (
    <div className='size-50px transition' id='toTop'
      onClick={scrollTop}>
      <ChevronUp />
    </div>
  )
}

export default ToTop