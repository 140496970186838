//Used to display text over background images (categories) that link to respective web-pages (categories)
//CategoryLinkProps are the parameters

//Required imports
import ArrowRight from '../icons/ArrowRight'
import { Link } from 'react-router-dom'
import '../../css/layouts.css'

//CategoryLinkProps Interface (Parameters)
interface CategoryLinkProps {
  href: string                    //link to the apropos webpage
  title: string                   //title to display over the image
  backgroundPath: string          //path to the background image to be displayed
  description?: string            //[OPTIONAL] description of the category
  backgroundAttachment?: string   //[OPTIONAL] specifies whether the background image is fixed
}

export const CategoryLink: React.FC<CategoryLinkProps> = ({ href, title, backgroundPath, description = ' ', backgroundAttachment = ' ', }) => {

  //the following lines slice a subtitle from the main title to ensure the trailing arrow is not on a newline
  const lastWordIndex = title.lastIndexOf(' ') //checks for whitespace before the last word of the title
  const mainTitle = title.slice(0, lastWordIndex) //slices the main title
  const subTitle = title.slice(lastWordIndex + 1) //slices the last word and trailing arrow

  return (
    <Link
      to={href}
      className="group rounded border border-transparent item  m-4 lg:m-4 transition-colors hover:border-gray-300 hover:dark:border-neutral-700"
    >
      <div className={backgroundAttachment + " item-image-container lg:px-10"} style={{
        backgroundColor: 'rgba(7,7,7,0.3)',
        backgroundImage: `url(\"/static/images/${backgroundPath}/0.png\")`,
      }} id={mainTitle.toLowerCase().replace(/\s+/g, '-')}>
        {/* Background image with blur effect */}
        <div className="background-image z-1"></div>
      </div>

      <div className="item-text-container py-3 lg:px-">
        <h2 className={`mb-1 text-2xl font-semibold`}>
          {mainTitle}{' '}
          <span className='subTitle whitespace-nowrap'>
            { subTitle && `${subTitle} ` }&nbsp;
            <span className="arrow-right motion-reduce:transform-none rel top-7px">
              <ArrowRight w={40} h={30}/>
            </span>
          </span>
        </h2>
        <p className={`m-1 imgDescription pad-3 px-1 lg:px-16 lg:text-lg font-semibold`}>
          {description}
        </p>
      </div>
    </Link>
  )
}

export default CategoryLink