import React from 'react'
import { WH } from '../interfaces/Sizes'

/**
 * @param {number} w - the width of the icon
 * @param {number} h - the height of the icon
 * @returns {React.JSX.Element}
*/
const ArrowRight: React.FC<WH> = ({w, h}: WH): React.JSX.Element => {
  return (
    <span>
      <svg xmlns="http://www.w3.org/2000/svg" width={w} height={h} fill="currentColor" viewBox="0 0 15 15" stroke='currentColor' strokeWidth='1'>
        <svg  width="100%" height="100%">
          <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8" />
       </svg>
      </svg>
    </span>
  )
}

export default ArrowRight
