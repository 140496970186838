import CategoryLink from '../layouts/CategoryLink'
import Footer from '../navigation/Footer'
import { useEffect } from 'react'

const Home = () => {

  //function to scroll to top
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    //scrollTo top of page
    window.scrollTo({ top: 0, behavior: 'smooth' })

    //constants to set the color of the .live page
    //identify current page
    const currentPage = window.location.href
    const testPages = document.querySelectorAll('.nav-link').forEach((page) => {
      if (currentPage.endsWith(page.id)) {
        page.classList.add('live')
      } else {
        page.classList.remove('live')
      }
    })
  })
  //return html to be rendered

  return (
    <div className='rel top-0' >
      {/*<video className='sticky top-0' height='100%' width='100%' data-object-fit="cover" data-wf-ignore='true' autoPlay={true} muted={true} loop={true} controls={false} playsInline poster={'/coming_soon.png'}>
        <source src={video} type="video/mp4" />
        Video not supported
      </video>
    */}
      <div className=' m:marT-5 pad-1'>
        <div className='center pad-3 '>
          <img className='fixed left-0 top-40px' src='/media/images/hero.jpeg' alt='Welcome image of a screened-in patio' width='100%'></img>
          <div className='alex-brush-cursive f-50px hero-color2 m:f-30px m:pad-5-1 pad-1 rel text-shad-gray white'>
            <br></br>Welcome To
            <h1>Ryan's Screens</h1>
            </div>
            <br></br>
            <br></br><br></br><br></br><br></br><br></br><br></br>
          <div className='bg-color m:abs m:w-100 rel white'>
          <h1 className='pad-3 text-shad-gray'>OUR SERVICES</h1>
            <div className='bg-color child-br-10px f-20px grid col-2 m:col-1 m:marT-5 pad-3 rel t:col-2 white'>
              <CategoryLink
                href='/patio-screens'
                title='Patio Screens'
                backgroundPath='patio'
                description={"Enhance your outdoor living space with durable and stylish patio screens for comfort and privacy."}
              />
              <CategoryLink
                href='/re-screening'
                title='Re-Screening '
                backgroundAttachment='m:backgroundAttachmentFixedLeft'
                backgroundPath='screen'
                description="Restore your screens to their original condition with our professional re-screening services."
              />
              <CategoryLink
                href='/enclosures'
                title='Pool Enclosures'
                backgroundPath='enclosure'
                description='Secure and beautify your pool area with custom-designed, high-quality pool enclosures.'
              />
              <CategoryLink
                href='/windows'
                title='Window Screens'
                backgroundAttachment='m:backgroundAttachmentFixedLeft'
                backgroundPath='window'
                description='Improve ventilation and keep pests out with our expertly crafted window screens.'
              />
            </div>
            
            <div className='bg-color center m:marT-5rem marT-3rem pad-1 rel'>
              <div className='black center col-2 f-40px grid m:col-1 pad-5 rel'>
                <div>
                  Welcome to Ryan's Screens, your premier destination for top-quality screen enclosure services.
                </div>
                <div className='pad-3'>
                  <img src='/media/images/1.jpg' alt="Welcome to Ryan's Screens" width='100%'></img>
                </div>
                <div className='pad-3'><br></br>
                  <img src='/media/images/hero.jpeg' alt="Welcome to Ryan's Screens" width='100%'></img>
                </div>
                <div className='pad-3'>
                  Whether you're looking to enhance your outdoor living space, secure your pool area, or simply need a screen repair, we've got you covered.
                </div>
              </div>
              <div className='black f-25px pad-5'>
                <div>
                  Our team of experienced professionals is dedicated to providing exceptional craftsmanship and unparalleled customer service, ensuring your satisfaction with every project.
                </div><br></br>
                <div>
                  Explore our range of services and discover how we can transform your home into a more comfortable and enjoyable space.
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
      
    </div>
  )
}

export default Home
