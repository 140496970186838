import Inquiry from "../Questionnaires/Inquiry"
import { useEffect } from 'react'
import SEO from "../SEO/SEO"
import Footer from "../navigation/Footer"

const ReScreening = () => {
   //function to scroll to top
   const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    //scrollTo top of page
    window.scrollTo({ top: 0, behavior: 'smooth' })

    //constants to set the color of the .live page
    //identify current page
    const currentPage = window.location.href
    const testPages = document.querySelectorAll('.nav-link').forEach((page) => {
      if (currentPage.endsWith(page.id)) {
        page.classList.add('live')
      } else {
        page.classList.remove('live')
      }
    })
  })
  //return html to be rendered
  return (
    <div className='center marT-40px pad-3'><br></br>
      <SEO title="Re-Screening | Ryan's Screens" description="Revitalize your existing screens with professional re-screening services from Pool Screen Experts. Restore the look and functionality of your screens with high-quality materials." keywords="Re-screening, screen repair, screen restoration, re-screening services, screen replacement, high-quality screens, screen maintenance, screen rejuvenation"/>
      <h1 className='text-shad-gray white'>Welcome to Our Re-Screening Services</h1><br></br>
      <p className='pad-3'>
        Revitalize your existing screens with our professional re-screening services. Whether your screens are torn, loose, or simply worn out, we can restore them to their original condition.
      </p>
      <div className='grid col-2 m:col-1'>
        <div className='text-align-left'>
          <h3>Benefits of Re-Screening</h3>
          <ul>
            <li><b>Cost Effective:</b> Save money by repairing instead of replacing your screens.</li>
            <li><b>Enhanced Appearance:</b>  Fresh screens improve the overall look of your home.</li>
            <li><b>Increased Longevity:</b> Extend the life of your screens with our quality re-screening.</li>
            <li><b>Improved Functionality:</b> Enjoy better visibility and protection with new screens.</li>
          </ul>
          <h3>What Our Service Includes</h3>
          <ul>
            <li><b>Assessment and Removal:</b> We assess the condition of your existing screens and carefully remove the old material.</li>
            <li><b>High-Quality Screen Material:</b> Choose from a variety of screen types to suit your needs.</li>
            <li><b>Professional Installation:</b> Our team ensures a tight, secure fit.</li>
            <li><b>Post-Installation Inspection:</b> We check the final installation to ensure everything meets our high standards.</li>
          </ul>
        </div>
        <div className='w-100'>
          <img width='100%' src='/static/images/screen/0.png'></img>
        </div>
      </div>
      <h2 className='red text-shad-lightgray'>Call or Message us Today!</h2>
      <h3><a className='red text-shad-lightgray' href='tel:(561) 339-5191'>(561) 339-5191</a></h3>
      <Inquiry /><br></br>
      <Footer />
    </div>
  )
}

export default ReScreening
