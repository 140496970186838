import Inquiry from "../Questionnaires/Inquiry"
import SEO from "../SEO/SEO"
import { useEffect } from 'react'
import Footer from "../navigation/Footer"

const PoolEnclosures = () => {
   //function to scroll to top
   const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    //scrollTo top of page
    window.scrollTo({ top: 0, behavior: 'smooth' })

    //constants to set the color of the .live page
    //identify current page
    const currentPage = window.location.href
    const testPages = document.querySelectorAll('.nav-link').forEach((page) => {
      if (currentPage.endsWith(page.id)) {
        page.classList.add('live')
      } else {
        page.classList.remove('live')
      }
    })
  })
  //return html to be rendered
  return (
    <div className='center marT-40px pad-3'><br></br>
    <SEO title="Pool Enclosures | Ryan's Screens" description="Secure and beautify your pool area with custom-designed pool enclosures from Pool Screen Experts. Enjoy increased safety, reduced maintenance, and enhanced aesthetics." keywords="Pool enclosures, custom pool enclosures, pool safety, pool maintenance reduction, backyard enhancement, pool area security, stylish pool enclosures"/>
      <h1 className='text-shad-gray white'>Welcome to Our Pool Enclosure Services</h1><br></br>
      <p className='pad-3'>
        Secure and beautify your pool area with our custom-designed pool enclosures. Our enclosures provide safety, reduce maintenance, and enhance the aesthetic appeal of your backyard.
      </p>
      <div className='grid col-2 m:col-1'>
        <div className='text-align-left'>
          <h3>Benefits of Pool Enclosures</h3>
          <ul>
            <li><b>Safety:</b> Prevent accidental falls and keep unwanted visitors out.</li>
            <li><b>Maintenance Reduction:</b> Keep debris, leaves, and insects out of your pool.</li>
            <li><b>Extended Swimming Season:</b> Enjoy your pool for more months of the year.</li>
            <li><b>Aesthetic Enhancement:</b> Improve the look of your backyard with a stylish enclosure.</li>
          </ul>
          <h3>What Our Service Includes</h3>
          <ul>
            <li><b>Custom Design:</b> Tailored enclosures that fit your pool area perfectly.</li>
            <li><b>Quality Materials:</b> Durable, weather-resistant materials that last.</li>
            <li><b>Expert Construction:</b> Professional installation for a secure, attractive finish.</li>
            <li><b>Optional Features:</b> Add lighting, doors, and other custom features to enhance functionality.</li>
          </ul>
        </div>
        <div className='w-100'>
          <img width='100%' src='/static/images/enclosure/0.png'></img>
        </div>
      </div>
      <h2 className='red text-shad-lightgray'>Call or Message us Today!</h2>
      <h3><a className='red text-shad-lightgray' href='tel:(561) 339-5191'>(561) 339-5191</a></h3>
      <Inquiry /><br></br>
      <Footer />
    </div>
  )
}

export default PoolEnclosures
