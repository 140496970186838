import Inquiry from "../Questionnaires/Inquiry"
import { useEffect } from 'react'
import SEO from "../SEO/SEO"
import Footer from "../navigation/Footer"

const PatioScreens = () => {
   //function to scroll to top
   const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    //scrollTo top of page
    window.scrollTo({ top: 0, behavior: 'smooth' })

    //constants to set the color of the .live page
    //identify current page
    const currentPage = window.location.href
    const testPages = document.querySelectorAll('.nav-link').forEach((page) => {
      if (currentPage.endsWith(page.id)) {
        page.classList.add('live')
      } else {
        page.classList.remove('live')
      }
    })
  })
  //return html to be rendered
  return (
    <div className='center marT-40px pad-3'><br></br>
    <SEO title="Patio Screens | Ryan's Screens" description="Enhance your outdoor living area with durable and stylish patio screens from Pool Screen Experts. Enjoy comfort, privacy, and protection from insects and the elements." keywords="Patio screens, outdoor living, patio screen installation, durable patio screens, stylish patio screens, insect protection, privacy screens, patio comfort" />
      <h1 className='text-shad-gray white'>Welcome to Our Patio Screen Services</h1><br></br>
      <p className='pad-3'>
        Enhance your outdoor living experience with our high-quality patio screen services. Whether you're looking to create a bug-free environment or simply want to add a touch of elegance to your patio, we've got you covered.
      </p>
      <div className='grid col-2 m:col-1'>
        <div className='text-align-left'>
          <h3>Benefits of Patio Screens</h3>
          <ul>
            <li><b>Increased Comfort:</b> Enjoy the outdoors without the nuisance of insects.</li>
            <li><b>Enhanced Privacy:</b> Patio screens provide a sense of privacy from neighbors and passersby.</li>
            <li><b>Improved Aesthetics:</b> Our stylish screens can enhance the look of your outdoor space.</li>
            <li><b>Protection from the Elements:</b> Shield your patio from wind, debris, and light rain.</li>
          </ul>
          <h3>What Our Service Includes</h3>
          <ul>
            <li><b>Custom Design and Installation:</b> Tailored to fit your specific patio dimensions and style preferences.</li>
            <li><b>Durable Materials:</b> We use high-quality materials that withstand the elements.</li>
            <li><b>Expert Installation:</b> Our experienced team ensures a perfect fit and finish.</li>
            <li><b>Maintenance and Repair:</b> Ongoing support to keep your screens in top condition.</li>
          </ul>
        </div>
        <div className='w-100'>
          <img width='100%' src='/static/images/patio/0.png'></img>
        </div>
      </div>
      <h2 className='red text-shad-lightgray'>Call or Message us Today!</h2>
      <h3><a className='red text-shad-lightgray' href='tel:(561) 339-5191'>(561) 339-5191</a></h3>
      <Inquiry /><br></br>
      <Footer />
    </div>
  )
}

export default PatioScreens
