import React from 'react'
import { Link } from 'react-router-dom'

/**
 * (interface) NavLinks Array<[string, string]>
 * @param {[string, string][]} links - an array of tuples containing two strings: the first specifying a url to a webpage or component, and the latter, a link title to be displayed
 */
interface NavLinks {
  links: Array<[string, string]>
}

/**
 *(React.FC) Nav Array<[string, string]>: <NaLinks>
 * @param {NavLinks} links - an array of tuples containing two strings: the first specifying a url to a webpage or component, and the latter, a link title to be displayed
 * @returns React.JSX.Element
 */
const Nav: React.FC<NavLinks> = ({ links }: NavLinks) => {
  return (
    <nav className='nav'>
      {links.map(([href, title], index) => (
        <Link className='hover-border-bottom nav-link transition' id={href} key={index} to={href}>{title}</Link>
      ))}
    </nav>
  )
}

export default Nav