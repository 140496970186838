import React from 'react'
import { Helmet } from 'react-helmet-async'

const SEO = ({title='', description='', keywords='', imageUrl='', imageSize='800'}) => {
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        {description !== '' && 
        <>
          <meta name='description' content={description} />
          <meta property="og:description" content={description} />
        </>}
        {keywords !== '' && <meta name='keywords' content={keywords}/>}
        {title !== '' && <meta property="og:title" content={title} />}
        {imageUrl !== '' && 
        <>
          <meta property="og:image" content={"%PUBLIC_URL%/" + imageUrl}/>
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content={imageSize} />
          <meta property="og:image:height" content={imageSize} />
        </>}
      </Helmet>
    </div>
  )
}

export default SEO