// SideMenu.tsx
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const MenuContainer = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  background: rgba(0, 68, 142, 0.97);
  color: white;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  padding: 2rem;
`;

const BurgerButton = styled.div<{ open: boolean }>`
  position: fixed;
  top: 15px;
  left: 15px;
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 1100;
  cursor: pointer;

  div {
    width: 30px;
    height: 4px;
    background: ${({ open }) => (open ? 'white' : '#333')};
    border-radius: 2px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    &:first-child {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    &:nth-child(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
      transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
    }

    &:nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;

const MenuItems = styled.ul`
  list-style: none;
  padding: 0;
`;

const MenuItem = styled.li`
  padding: 1rem 0;
  font-size: 1.5rem;
`;

const SideMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <BurgerButton open={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <div />
        <div />
        <div />
      </BurgerButton>
      <MenuContainer open={isOpen}>
        <MenuItems>
          <MenuItem>
            <Link to="/" className='nav-link' id='/' onClick={() => setIsOpen(false)}>Home</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/patio-screens" className='nav-link' id='patio-screens' onClick={() => setIsOpen(false)}>Patio Screens</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/re-screening" className='nav-link' id='re-screening' onClick={() => setIsOpen(false)}>Re-Screening</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/enclosures" className='nav-link' id='enclosures' onClick={() => setIsOpen(false)}>Pool Enclosures</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/windows" className='nav-link' id='windows' onClick={() => setIsOpen(false)}>Window Screens</Link>
          </MenuItem>
          <MenuItem>
            <Link to="/about" className='nav-link' id='about' onClick={() => setIsOpen(false)}>About</Link>
          </MenuItem>
        </MenuItems>
      </MenuContainer>
    </>
  );
};

export default SideMenu;
