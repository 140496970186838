import Inquiry from "../Questionnaires/Inquiry"
import SEO from "../SEO/SEO"
import { useEffect } from 'react'
import Footer from "../navigation/Footer"

const WindowScreens = () => {

  useEffect(() => {
    //scrollTo top of page
    window.scrollTo({ top: 0, behavior: 'smooth' })

    //constants to set the color of the .live page
    //identify current page
    const currentPage = window.location.href
    const testPages = document.querySelectorAll('.nav-link').forEach((page) => {
      console.log(page)
      if (currentPage.endsWith(page.id)) {
        page.classList.add('live')
      } else {
        page.classList.remove('live')
      }
    })
  }, [])
  //return html to be rendered
  return (
    <div className='center marT-40px pad-3'><br></br>
    <SEO title="Window Screens | Ryan's Screens" description="Improve your home's ventilation and keep pests out with expertly crafted window screens from Pool Screen Experts. Custom-fit, durable, and high-visibility options available." keywords="Window screens, custom window screens, insect protection, home ventilation, durable window screens, high-visibility screens, window screen installation, pest prevention" />
      <h1 className='text-shad-gray white'>Welcome to Our Window Screen Services</h1><br></br>
      <p className='pad-3'>
      Improve your home's ventilation and keep pests out with our expertly crafted window screens. We offer a variety of options to fit any window style and size.


      </p>
      <div className='grid col-2 m:col-1'>
        <div className='text-align-left'>
          <h3>Benefits of Window Screens</h3>
          <ul>
            <li><b>Pest Prevention:</b> Keep insects and other pests outside where they belong.</li>
            <li><b>Improved Ventilation:</b> Enjoy fresh air without worrying about bugs.</li>
            <li><b>Energy Efficiency:</b> Reduce the need for air conditioning by allowing natural ventilation.</li>
            <li><b>Enhanced Home Value:</b> Well-maintained screens add to your home's curb appeal.</li>
          </ul>
          <h3>What Our Service Includes</h3>
          <ul>
            <li><b>Custom Fit:</b> Screens tailored to fit your windows perfectly.</li>
            <li><b>Quality Materials:</b> Durable, high-visibility screening materials.</li>
            <li><b>Professional Installation:</b> Expertly installed for a seamless look.</li>
            <li><b>Repair and Maintenance:</b> Ongoing support to ensure your screens stay in top condition.</li>
          </ul>
        </div>
        <div className='w-100'>
          <img width='100%' src='/static/images/window/0.png'></img>
        </div>
      </div>
      <h2 className='red text-shad-lightgray'>Call or Message us Today!</h2>
      <h3><a className='red text-shad-lightgray' href='tel:(561) 339-5191'>(561) 339-5191</a></h3>
      <Inquiry /><br></br>
      <Footer />
    </div>
  )
}

export default WindowScreens
