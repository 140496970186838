import { Link } from 'react-router-dom'
import Nav from './Nav'

const Footer = () => {
  /**
   *  <Link to="/" className='footerNav ' id="/">HOME</Link>
          <Link to='/pricing' className='footerNav  ' id='pricing'>PRICING</Link>
          <Link to='/weare' className='footerNav  ' id='weare'>WHO WE ARE</Link>
          <Link to='/development' className='footerNav   ' id='development'>DEVELOPMENT GUIDE</Link>
   */

            /**   2226
 * An Array of Tuples containing <Link /> urls and titles
 */
  const headerLinks: Array<[string, string]> = [
    ['/', 'Home'],
    ['/patio-screens', 'Patio Screens'],
    ['/re-screening', 'Re-Screening'],
    ['/enclosures','Pool Enclosures'],
    ['/windows','Window Screens'],
    ['/about', 'About']
  ]

  return (
    <div className='bd-blur-7px bg-hero-color-transparent footer pad-3-0 text-shad-gray white'>
      <div className='col-3 grid m:col-1 '>
        <div className='col-2 footerLogo grid pad-1-5 w-20rem'>
            <Link to="/" className='' id="/">
                <img className='mobile-hide wide-show' height='auto' width='100%' src='/logo.png' alt="Ryan's Screens Logo"></img>
            </Link>
            <div>
                <h3>Ryan's Screen Service</h3>
                <div className='f-22px'><a href='mailto:RyanKenny@usa.com'>RyanKenny@usa.com</a></div><br></br>
                <div className='f-25px'><a href='tel:(561) 339-5191'>(561) 339-5191</a></div>
                
            </div>
        </div>
        <div className='footerMenu pad-1 m:pad-3'>
          <Nav links={headerLinks} />
        </div>
        <div className='w-100px'>

        </div>
      </div>

      <div className='center f-15px flex pad-3 w:pad-1  white'>
        <a href='https://www.jbridgewater.com' target='_blank' rel='noopener noreferrer' >
          A&nbsp;<img className='m:w-301 w-8rem' height='auto' width='150px' src='/media/images/BRIDGEWATER_WHITE_LOGO.png' alt='JBRIDGEWATER Logo'></img>&nbsp;production. &copy; 2024
        </a>
      </div><br></br>
    </div>
  )
}

export default Footer