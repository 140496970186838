import React from 'react'
import { WH } from '../interfaces/Sizes'

const ChevronUp: React.FC<WH> = ({ w = 16, h = 16 }): React.JSX.Element => {
  return (
    <svg className='center' xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" viewBox="0 0 15 15" stroke='currentColor' strokeWidth='1'>
      <svg width='100%' height='100%'>
        <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
      </svg>
    </svg>
  )
}

export default ChevronUp