import React from 'react'
import SEO from '../SEO/SEO'
import Footer from '../navigation/Footer'
import { useEffect } from 'react'

const About = () => {
   //function to scroll to top
   const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    //scrollTo top of page
    window.scrollTo({ top: 0, behavior: 'smooth' })

    //constants to set the color of the .live page
    //identify current page
    const currentPage = window.location.href
    const testPages = document.querySelectorAll('.nav-link').forEach((page) => {
      if (currentPage.endsWith(page.id)) {
        page.classList.add('live')
      } else {
        page.classList.remove('live')
      }
    })
  })
  //return html to be rendered

  return (
    <div className='center marT-40px pad-3'>
      <SEO title="About Us | Ryan's Screens" description='Learn about Pool Screen Experts, your trusted partner for screen installation and repair services. We specialize in patio screens, re-screening, pool enclosures, and window screens with a focus on quality craftsmanship and customer satisfaction.' keywords='About Pool Screen Experts, screen installation, screen repair, patio screens, re-screening, pool enclosures, window screens, quality craftsmanship, customer satisfaction' />
      <img className='fixed left-0 top-40px' src='/media/images/1.jpg' alt='Welcome image of a screened-in patio' width='100%'></img><br></br>
      <h1 className='text-shad-gray rel white'>Welcome to Ryan's Screens</h1><br></br><br></br><br></br><br></br><br></br>
      <div className='bg-color m:abs m:w-100 pad-5 rel'>
      <p className='pad-3 rel'>
        At Ryan's Screens, we are dedicated to providing top-notch screen installation and repair services. Our mission is to enhance your outdoor living spaces and improve the functionality and appearance of your home.
      </p>
      <div className='grid col-2 m:col-1 rel'>
        <div className='text-align-left'>
          <h3>Who We Are</h3>
          <ul>
            <li><b>Experienced Professionals:</b> Our team consists of skilled experts with years of experience in the industry.</li>
            <li><b>Quality Craftsmanship:</b> We take pride in our attention to detail and commitment to excellence.</li>
            <li><b>Customer Focused:</b> Your satisfaction is our top priority, and we strive to exceed your expectations.</li>
          </ul>
          <h3>Our Services</h3>
          <ul>
            <li><b>Patio Screens:</b> Custom installations that provide comfort and privacy.</li>
            <li><b>Re-Screening:</b> Professional services to restore your screens to their best condition.</li>
            <li><b>Pool Enclosures:</b> Secure, stylish enclosures that enhance your pool area.</li>
            <li><b>Window Screens:</b>  High-quality screens that improve ventilation and keep pests out.</li>
          </ul>
          <h3>Our Commitment</h3>
          <ul>
            <li><b>Quality Materials:</b> We use only the best materials to ensure durability and performance.</li>
            <li><b>Competitive Pricing:</b> Affordable rates without compromising on quality.</li>
            <li><b>Exceptional Service:</b> From consultation to installation, we provide a seamless experience.</li>
          </ul>
        </div>
        <div className='w-100'>
          <img width='100%' src='/static/images/patio/0.png'></img>
        </div>
      </div>
      <p className='pad-3 rel'>
        Thank you for choosing Ryan's Screens. We look forward to helping you create a more enjoyable and functional home environment.
      </p><br></br>
      <Footer />
      </div>
      
    </div>
  )
}

export default About
