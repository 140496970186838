import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import './App.css';

import About from './components/pages/About';
import Header from './components/navigation/Header';
import { HelmetProvider } from 'react-helmet-async';
import Home from './components/pages/Home';
import PoolEnclosures from './components/pages/PoolEnclosures';
import PatioScreens from './components/pages/PatioScreens';
import ReScreening from './components/pages/ReScreening';
import WindowScreens from './components/pages/WindowScreens';
import ToTop from './components/navigation/ToTop';


function App() {
  return (
    <HelmetProvider>
      <Router>
        <div className="App">
          <Header />
          <ToTop />
          <Routes>
            <Route path='/*' element={<Home />}/>
            <Route path='/patio-screens' element={<PatioScreens />}/>
            <Route path='/re-screening' element={<ReScreening />}/>
            <Route path='/enclosures' element={<PoolEnclosures />}/>
            <Route path='/windows' element={<WindowScreens />}/>
            <Route path='/about' element={<About/>}/>
          </Routes>

        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;