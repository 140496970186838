import React from 'react'
import DropDownNav from './DropDownNav'
import Nav from './Nav'
import { Link } from 'react-router-dom'
import SideMenu from './SideMenu'

const Header = () => {
  /**
 * An Array of Tuples containing <Link /> urls and titles
 */
  const headerLinks: Array<[string, string]> = [
    ['/', 'Home'],
    ['/patio-screens', 'Patio Screens'],
    ['/re-screening', 'Re-Screening'],
    ['/enclosures','Pool Enclosures'],
    ['/windows','Window Screens'],
    ['/about', 'About']
  ]
/**
 * the rendered Header component
 * <DropDownNav title='TITLE' links={dropDownMenuLinks} /
 */
return (
  <div className='contain'>
    <div className='header white text-shad-gray'>
    <div className='center bd-blur-15px fixed h-50px m:h-63px m:bd-blur-5px top-0'></div>
      <div className='bd-blur-7px bg-hero-color-transparent grid col-3 header-container m:col-1'>    
        <div className='heading-shadow m:text-align-right'>
          <Link to="/"><img className='heading' height='auto' width='100%' src='/logo.png' alt='Logo'></img></Link>
        </div>
        <div className='w:disp-none'>
          <SideMenu />
        </div>
        <div className='m:disp-none m+l:disp-none pad-3-1'>
          
          <Nav links={headerLinks} />
        </div>
        <div className='inline-block w-200px'></div>
      </div>
    </div>
  </div>
)
}

export default Header
